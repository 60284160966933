$height: 50px;
$backgroundColor: #0b5580;

.modal {
  height: 750px;
  overflow: auto;
}

.box {
  height: 15px;
  padding: 9px 12px 9px 10px;
  border-radius: 2px;
  border: solid 1px #979797;
  background-color: #ffffff;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.submit {
  position: relative;
  width: 320px;
  height: 50px;
  margin: 68px 0px 0 0;
  padding: 14px 24px 14px 26px;
  border-radius: 8px;
  background-color: #0e8cb3;
  text-decoration: none;
  border: none;
  font-size: 18px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  span {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: white;
    font-size: 18px;
    &.arrow {
      right: 15px;
    }
  }
}

.top-menu {
  position: fixed;
  display: table;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: $backgroundColor;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);

  .title {
    position: absolute;
    left: 0;
    margin-top: 8px;
    margin-left: 20rem;
    top: 1rem;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .menu-button {
    position: absolute;
    left: 20px;
    height: 2px;
    width: 20px;
    background: #fff;
    /*Center according to top menu and height of middle bar*/
    top: calc(65px / 2 - 5px);
    cursor: pointer;
    transition: background ease 0.3s;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 6px;
      height: 100%;
      width: 100%;
      background: #fff;
      transition: all ease 0.3s;
    }
    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      height: 100%;
      width: 100%;
      background: #fff;
      transition: all ease 0.3s;
    }

    &[data-menu='right'] {
      right: 20px;
      left: auto;
    }
  }

  .menu-button.is-clicked {
    /*Set opacity of middle bar to 0*/
    background: rgba(255, 255, 255, 0);
    transition: background ease 0.3s;
    &:before {
      background: #fff;
      transform: rotate(45deg);
      bottom: 0;
      transition: all ease 0.3s;
    }
    &:after {
      background: #fff;
      transform: rotate(-45deg);
      top: 0;
      transition: all ease 0.3s;
    }
  }
}

.logo {
  color: whitesmoke;
  padding-left: 0;
  padding-bottom: 0;
  width: 150px;
  height: 100%;
  background-color: white;
}

.weather {
  position: absolute;
  left: 5rem;
}

.titleModal {
  padding-left: 2rem;
  padding-top: 2rem;
}

.login-button {
  position: absolute;
  top: 0.75rem;
  right: 20rem;
  height: 134px;
  width: 28px;
  span {
    display: block;
    background-color: #0e8cb3;
    color: #ffffff;
    text-decoration: none;
    // font-family: Verdana, Geneva, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    line-height: 3.5rem;
    padding: 1px 4px;
    margin: 4px 20px;
    border: none;
    border-radius: 3px;
    width: 12rem;
    // font-size: 1.4rem;

    &:hover {
      border: 0.1rem solid white;
      cursor: pointer;
    }
  }
}

.status-button {
  position: absolute;
  top: 0.75rem;
  right: 40rem;
  height: 134px;
  width: 28px;
  span {
    display: block;
    background-color: #0e8cb3;
    color: #ffffff;
    text-decoration: none;
    // font-family: Verdana, Geneva, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    line-height: 3.5rem;
    padding: 1px 4px;
    margin: 4px 20px;
    border: none;
    border-radius: 3px;
    width: 12rem;
    // font-size: 1.4rem;

    &:hover {
      border: 0.1rem solid white;
      cursor: pointer;
    }
  }
}

.incidence-button {
  position: absolute;
  top: 0.75rem;
  right: 60rem;
  height: 134px;
  width: 28px;
  span {
    display: block;
    background-color: #0e8cb3;
    color: #ffffff;
    text-decoration: none;
    // font-family: Verdana, Geneva, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    line-height: 3.5rem;
    padding: 1px 4px;
    margin: 4px 5px;
    border: none;
    border-radius: 3px;
    width: 18rem;
    // font-size: 1.4rem;

    &:hover {
      border: 0.1rem solid white;
      cursor: pointer;
    }
  }
}
