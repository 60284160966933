// VARiABLES
// desktop grid
$grid-desktop-columns: 15;
$grid-desktop-sideMargin: 0px;
$grid-desktop-gutter: 20px;
$grid-desktop-breakpoint: 1600px;

// tablet grid
$grid-tablet-columns: 15;
$grid-tablet-sideMargin: 0px;
$grid-tablet-gutter: 30px;
$grid-tablet-breakpoint: 1020px;

// mobile grid
$grid-mobile-columns: 4;
$grid-mobile-sideMargin: 0px;
$grid-mobile-gutter: 20px;
$grid-mobile-breakpoint: 620px;

// pixels to % variables
$desk-grid-width: 100% - (($grid-desktop-sideMargin * 2 / $grid-desktop-breakpoint) * 100%);
$tab-grid-width: 100% - (($grid-tablet-sideMargin * 2 / $grid-tablet-breakpoint) * 100%);
$mob-grid-width: 100% - (($grid-mobile-sideMargin * 2 / $grid-mobile-breakpoint) * 100%);

$desk-gutter: (($grid-desktop-gutter / ($grid-desktop-breakpoint - ($grid-desktop-sideMargin * 2))) * 100%);
$tab-gutter: (($grid-tablet-gutter / ($grid-tablet-breakpoint - ($grid-tablet-sideMargin * 2))) * 100%);
$mob-gutter: (($grid-mobile-gutter / ($grid-mobile-breakpoint - ($grid-mobile-sideMargin * 2))) * 100%);

// FUNCTIONS AND MiXINS
@function col-width($i, $columns) {
  @return 100% / $columns * $i;
}

@mixin col-props($i, $columns, $gutter) {
  box-sizing: border-box;
  width: col-width($i, $columns);
  padding-top: $gutter;
  padding-left: $gutter/2;
  padding-right: $gutter/2;
}

@mixin columns-loop($name, $columns, $gutter) {
  @for $i from 0 through $columns {
    &-#{$name}-#{$i} {
      @include col-props($i, $columns, $gutter);
    }
    &-#{$name}-shift-#{$i} {
      margin-left: col-width($i, $columns);
    }
  }
}

@mixin grid-base($width) {
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  max-width: $grid-desktop-breakpoint;
  width: $width;
}

// CLASSES
.grid {
  @include grid-base($desk-grid-width);
  @media screen and (max-width: $grid-tablet-breakpoint) {
    width: $tab-grid-width;
  }
  @media screen and (max-width: $grid-mobile-breakpoint) {
    width: $mob-grid-width;
  }
  &-0 {
    @include grid-base(100%);
  }
}

.col {
  @include columns-loop(desk, $grid-desktop-columns, $desk-gutter);
  @media screen and (max-width: $grid-tablet-breakpoint) {
    @include columns-loop(tab, $grid-tablet-columns, $tab-gutter);
  }
  @media screen and (max-width: $grid-mobile-breakpoint) {
    @include columns-loop(mob, $grid-mobile-columns, $mob-gutter);
  }
}
