/*  BURGER
  ========================================== */
.burger {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 5px;
  right: 20px;
  border-radius: 4px;
  z-index: 10;
}
.burger span {
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}
.burger span,
.burger span::before,
.burger span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.burger span::before,
.burger span::after {
  position: absolute;
  content: '';
}
.burger span::before {
  top: -9px;
}
.burger span::after {
  top: 9px;
}
.burger.clicked span {
  background-color: transparent;
}
.burger.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -moz-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}
.burger.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -moz-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}
.burger.clicked span:before,
.burger.clicked span:after {
  background-color: #ffffff;
}
.burger:hover {
  cursor: pointer;
}

/*  NAV
  ========================================== */
nav {
  background-color: #2a2a2a;
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 250px;
  width: 100%;
  padding: 100px 40px 60px 40px;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
nav.show {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
nav.show ul.main li {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}
nav.show ul.main li:nth-child(1) {
  transition-delay: 0.15s;
}
nav.show ul.main li:nth-child(2) {
  transition-delay: 0.3s;
}
nav.show ul.main li:nth-child(3) {
  transition-delay: 0.45s;
}
nav.show ul.main li:nth-child(4) {
  transition-delay: 0.6s;
}
nav.show ul.main li:nth-child(5) {
  transition-delay: 0.75s;
}
nav.show ul.main li:nth-child(6) {
  transition-delay: 0.9s;
}
nav.show ul.main li:nth-child(7) {
  transition-delay: 1.05s;
}
nav.show ul.main li:nth-child(8) {
  transition-delay: 1.2s;
}
nav.show ul.main li:nth-child(9) {
  transition-delay: 1.35s;
}
nav.show .about,
nav.show .social,
nav.show ul.sub {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.85s;
}
@media (min-width: 667px) {
  nav {
    padding: 120px 90px 70px 90px;
  }
}
nav ul.main {
  list-style-type: none;
}
nav ul.main li {
  margin-bottom: 20px;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
nav ul.main li:last-of-type {
  margin-bottom: 0px;
}
nav ul.main li a {
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: block;
  letter-spacing: 5px;
  font-weight: 600;
  padding: 10px 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
nav ul.main li a span {
  color: #b7ac7f;
}
nav ul.main li a:hover {
  color: #b7ac7f;
}
