.container {
  position: relative;
  padding-left: 0.5em;
  color: #4a4a4a;
  font-size: 2rem;
  h1 {
    color: #0b5580;
    font-size: 2.5rem;
  }

  strong {
    font-weight: bolder;
    font-size: 2.2rem;
  }

  img {
    padding-left: 5rem;
  }
}

.list {
  display: flex;
  padding-left: 5rem;
}
