/* GLOBAL RESET */
:global(html),
:global(body),
:global(div),
:global(span),
:global(applet),
:global(object),
:global(iframe),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(p),
:global(blockquote),
:global(pre),
:global(a),
:global(abbr),
:global(acronym),
:global(address),
:global(big),
:global(cite),
:global(code),
:global(del),
:global(dfn),
:global(em),
:global(img),
:global(ins),
:global(kbd),
:global(q),
:global(s),
:global(samp),
:global(small),
:global(strike),
:global(strong),
:global(sub),
:global(sup),
:global(tt),
:global(var),
:global(b),
:global(u),
:global(i),
:global(center),
:global(dl),
:global(dt),
:global(dd),
:global(ol),
:global(ul),
:global(li),
:global(fieldset),
:global(form),
:global(label),
:global(legend),
:global(table),
:global(caption),
:global(tbody),
:global(tfoot),
:global(thead),
:global(tr),
:global(th),
:global(td),
:global(article),
:global(aside),
:global(canvas),
:global(details),
:global(embed),
:global(figure),
:global(figcaption),
:global(footer),
:global(header),
:global(hgroup),
:global(menu),
:global(nav),
:global(output),
:global(ruby),
:global(section),
:global(summary),
:global(time),
:global(mark),
:global(audio),
:global(video) {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: inherit;
}

/* HTML5 display-role reset for older browsers */
:global(article),
:global(aside),
:global(details),
:global(figcaption),
:global(figure),
:global(footer),
:global(header),
:global(hgroup),
:global(menu),
:global(nav),
:global(section) {
  display: block;
}
:global(body) {
  line-height: 1;
}
:global(ol),
:global(ul) {
  list-style: none;
}
:global(blockquote),
:global(q) {
  quotes: none;
}
:global(blockquote:before),
:global(blockquote:after),
:global(q:before),
:global(q:after) {
  content: '';
  content: none;
}
:global(table) {
  border-collapse: collapse;
  border-spacing: 0;
}

/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/rubik-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../fonts/rubik-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/rubik-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/rubik-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/rubik-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/rubik-v10-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-Bold';
  src: local(''), url('../../fonts/rubik-bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-Medium';
  src: local(''), url('../../fonts/Rubik-Medium.ttf') format('truetype');
  font-display: swap;
}

body {
  font-family: 'Rubik';
  box-sizing: border-box;
  background-color: $color-background;
  padding-top: 5rem;
}

html {
  // this defines what 1rem is --> font root size
  font-size: 62.5%; // 10/16, 1rem = 10px;

  @include responsive(tab_port) {
    font-size: 50%;
  }
}

.container {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: 4rem;
}

// GRID AREA
// .container {
//   display: grid;
//   grid-template-rows: min-content min-content min-content min-content;
//   grid-template-columns: 4% repeat(10, 1fr) 4%;
// }
